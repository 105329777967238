import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/design/:projectId/:viewId?',
    name: 'Design',
    component: () => import('../views/design.vue')
  },
  {
    path: '/run/:projectId/:viewId?',
    name: 'Run',
    component: () => import('../views/run.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
