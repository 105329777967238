
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import firebase from "firebase";

export default Vue.extend({
  name: "App",

  data: () => ({
    email: '',
    password: '',
    register: false,
    passCode: '',

    alert: false,
    error: '',

    log: '',
  }),

  computed: {
    ...mapState(["session"]),
  },

  mounted() {
    /*
    const oldLog = console.log;
    const oldError = console.error;
    const oldWarn = console.warn;
    const vm = this;
    window.console.log = function (text: any) {
      oldLog(text);
      // Your code
      vm.log += `<br/><div class="code">${text}</div>`;
    };
    window.console.error = function (text: any) {
      oldError(text);
      // Your code
      vm.log += `<br/><div class="code error">${text}</div>`;
    };
    window.console.warn = function (text: any) {
      oldWarn(text);
      // Your code
      vm.log += `<br/><div class="code warning">${text}</div>`;
    };
    */
  },

  methods: {
    ...mapMutations({
      startSession: "START_SESSION",
      endSession: "END_SESSION",
    }),

    showError(msg: string) {
      this.alert = true;
      this.error = msg;
      setTimeout(() => {
        this.alert = false;
      }, 3000);
    },

    login() {
      console.log('login');
      if (this.register) {
        if (this.passCode == 'falafel') {
          firebase
            .auth() // get the auth api
            .createUserWithEmailAndPassword(this.email, this.password) // need .value because ref()
            .then((data: any) => {
              //         router.push("/feed"); // redirect to the feed
              this.startUserSession(data.user);
              this.register = false;
            })
            .catch((error: any) => {
              console.log(error.code);
              this.showError(error.message);
            });
        } else {
          this.showError('Invalid pass code');
        }
      } else {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then((data: any) => {
            this.startUserSession(data.user);
            console.log("logged in");
            //          this.$router.push({ path: "/" });
          })
          .catch((error: any) => {
            this.session.user = null;
            this.showError(error);
          });
      }
    },

    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.endSession();
          this.session.user = null;
        });
    },

    startUserSession(usr: any) {
      const user = usr? usr : { email: "Guest" };
      this.startSession({ user: user });
      this.session.user = user;
    },
  }
});
