import { Project } from '@/code/project'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from "vuex-persist";

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {
    session: {
      user: null,
      projects: [] as Project[],
    },
  },
  mutations: {
    ADD_PROJECT(state, payload) {
      state.session.projects.push(payload);
    },

    START_SESSION(state, payload) {
      state.session.user = payload.user;
    },

    END_SESSION(state) {
      state.session.user = null;
      state.session.projects = [];
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin],
})
