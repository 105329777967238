import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import VueCodemirror from "vue-codemirror";

import "codemirror/lib/codemirror.css";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/htmlmixed/htmlmixed.js";
import "codemirror/mode/vue/vue.js";
import "codemirror/theme/base16-light.css";

import JSZip from "jszip";

Vue.use(JSZip);

Vue.config.productionTip = false;

Vue.use(VueCodemirror, {
  options: { theme: "base16-light" },
});

Vue.config.productionTip = false;

import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDbeao06H6CqtPw_5veiRkpM_dWw5I9lMw",
  authDomain: "visualvue-11125.firebaseapp.com",
  databaseURL: "https://visualvue-11125-default-rtdb.firebaseio.com",
  projectId: "visualvue-11125",
  storageBucket: "visualvue-11125.appspot.com",
  messagingSenderId: "152245006628",
  appId: "1:152245006628:web:99189debb6dab4f0a3c3b1",
  measurementId: "G-Y0VZ8XJ3NE",
};
firebase.initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
